<template lang="pug">
.pagination
  a(href="", :class="{ hidden: currentPage <= 1 }", @click.prevent="currentPage = Math.max(1, currentPage - 1)")
    | &lt;

  a(href="", :class="{ current: currentPage == 1 }", @click.prevent="currentPage = 1")
    | 1

  .middle-page(v-for="(page, index) in middlePages")
    .ellipsis(v-if="(index == 0 && page > 2) || (index == middlePages.length - 1 && page < pages - 1)") ...

    a(v-else, href="", :class="{ current: page == currentPage }",  @click.prevent="currentPage = page")
      | {{ page }}

  a(href="", :class="{ current: currentPage == pages }", @click.prevent="currentPage = pages")
    | {{ pages }}

  a(href="", :class="{ hidden: currentPage >=  pages }", @click.prevent="currentPage = Math.min(pages, currentPage + 1)")
    | &gt;
</template>
<script setup lang="ts">
const props = defineProps({
  itemsPerPage: {
    type: Number,
    default: 10
  },
  currentPage: {
    type: Number,
    default: 1
  },
  totalCount: {
    type: Number
  }
})

const emit = defineEmits(['update:currentPage'])

const currentPage = computed({
  get: () => props.currentPage,
  set: (value) => emit('update:currentPage', value)
})

const itemsPerPage = computed(() => {
  return props.itemsPerPage
})

const siblings = 1

const pages = computed(() => {
  if (props.totalCount) {
    return Math.ceil(props.totalCount / itemsPerPage.value)
  } else {
    return 0
  }
})

const middlePages = computed(() => {
  const numberOfPages =  Math.min(pages.value - 2, siblings * 2 + 3)

  const start =
    currentPage.value - siblings - 1 < 2 ? 2 :
      (currentPage.value + siblings + 1 > pages.value - 1 ? pages.value - numberOfPages : currentPage.value - siblings - 1)

  const end = Math.min(pages.value - 1, start + numberOfPages - 1)

  const mp = []
  for (var i = start; i <= end; i++) {
    mp.push(i)
  }

  return mp
})
</script>
<style lang="sass" scoped>
@import '~/assets/styles/variables'

.pagination
  display: flex
  flex-direction: row
  justify-content: center
  gap: 8px
  margin-top: 16px

  a, .ellipsis
    display: inline-flex
    align-items: center
    justify-content: center
    cursor: pointer
    height: 32px
    width: 32px
    text-align: center
    font-weight: bold
    user-select: none

  a
    background-color: $beige
    border-radius: 4px

    &.current
      color: white
      background-color: $acquaBlue
      border-color: $acquaBlue

    &.hidden
      pointer-events: none
      opacity: 0
</style>