<template lang="pug">
.remote-paginated-list

  .remote-paginated-list-results(v-if="!loading && !error && currentItems.length > 0")
    slot(:items="currentItems")

    Pagination(
      v-if="totalCount > itemsPerPage"
      v-model:currentPage="currentPage"
      :itemsPerPage="itemsPerPage"
      :totalCount="totalCount"
    )

  .remote-paginated-list-loading(v-else-if="loading")
    slot(name="loading")
      Loading


  .remote-paginated-list-error(v-else-if="error")
    slot(name="error", :error="error")
      | {{ error }}

  .remote-paginated-list-no-result(v-else-if="currentItems.length == 0")
    slot(name="no-result")
      | Nenhum resultado encontrado


</template>
<script setup lang="ts">
import type { Search, SearchFilter, SearchResult, SearchOrder } from 'index'
import { reportOnError } from '~/composables/sentry';

interface Breakpoint {
  itemsPerPage: number
}

const props = defineProps({
  name: String,
  breakpoints: {
    type: Object as PropType<{ [key: number]: Breakpoint }>,
  },
  itemsPerPage: {
    type: Number,
    default: 10
  },
  search: {
    type: Function as PropType<(search: Search) => Promise<SearchResult<any>>>,
    required: true
  },
  query: String,
  orderBy: Object as PropType<SearchOrder>,
  filters: Object as PropType<SearchFilter[]>,

})

const windowSize = useWindowSize()

const currentPage = ref(1)

const itemsPerPage = computed(() => {
  var itemsPerPage = props.itemsPerPage
  var lastBreakpoint = null

  if (props.breakpoints) {
    for (const key in props.breakpoints) {
      const breakpoint = Number(key)

      if ((windowSize.width.value === Infinity || windowSize.width.value >= breakpoint) && (!lastBreakpoint || breakpoint > lastBreakpoint)) {
        itemsPerPage = props.breakpoints[breakpoint].itemsPerPage
        lastBreakpoint = breakpoint
      }
    }
  }

  return itemsPerPage
})

const currentItems = computed(() => searchResult.value?.results?.slice(0, itemsPerPage.value) ?? [])
const totalCount = computed(() => searchResult.value?.totalCount ?? 0)

const resultId = useState(() => 0)
const currentId = resultId.value++

const loading = ref(false)
var loadingTimeoutId: ReturnType<typeof setTimeout>  | null = null
const setLoading = () => {
  loading.value = pending.value
  loadingTimeoutId = null
}

const triggerLoading = () => {
  if (loadingTimeoutId) {
    clearTimeout(loadingTimeoutId)
  }

  loadingTimeoutId = setTimeout(setLoading, 150)
}

const { data: searchResult, pending, error, refresh } = await useAsyncData(props.name ?? 'remote-pagination-result-' + currentId, () => props.search({
  query: props.query,
  filters: props.filters,
  orderBy: props.orderBy,
  page: currentPage.value - 1,
  pageSize: itemsPerPage.value
}))

reportOnError(error, 'Failed to load remote pagination')

watch(() => pending.value, (oldValue, newValue) => {
  if (oldValue !== newValue) {
    triggerLoading()
  }
})

watch(() => [currentPage.value, itemsPerPage.value], (oldValue, newValue) => {
  if (oldValue[0] !== newValue[0] || oldValue[1] !== newValue[1]) {
    refresh()
  }
})
</script>